
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-button{
    margin-top: 4px !important;
    padding-bottom: 8px !important;
}
.deleicon:hover{
    color: red;
    cursor: pointer;
}
.svgimg{
    position: relative;
    width: 100%;
        top: -10%;
}
.widget_header{
    color: black !important;
    margin-bottom: 0px;
    font-size: 16px;
}
.display_widget{
    color: #080f1a;
    font-family: euclidcircularb,sans-serif;
    font-size: 14px;
    line-height: 1.28571429;
}
.check_custom{
    margin-top: -5px !important;
    margin-left: 65px !important;
}
.check_chat{
    padding-left: 85px !important;
}
.emailinputandtext{
    display: flex;
    justify-content: space-between;
}
.display_chat{
    color: #080f1a;
    font-family: euclidcircularb,sans-serif;
    font-size: 14px;
    line-height: 1.28571429;
}
.select_device{
    border-radius: 0px !important;
}
.hide_screen{
    margin-left: 164px;
    margin-top: 0px;
    font-size: small;
    color: #0566ff !important;
}
.color_small{
    color: #0566ff !important;
    cursor: pointer;
}
.form-switch{
    width: 100%;
}
.sendedemail{
    padding: 0px;
    margin: 10px 0px 0px 0px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    font-family: serif;
    border-bottom: 2px solid lightgray;
    border-top: 2px solid lightgray;
    animation: messagesend 2s linear ;
   
}

@keyframes messagesend {
    from{
        position: relative;
        right: -100%;
    }
    to{
        position: relative;
        right: 0%;
    }
}
.dropdtacard{
    display: flex;
    align-items: center;
    margin: 10px;
}
.prewithdrop{
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: space-between;
}
.status_p{
    color: #080f1a;
    font-family: euclidcircularb,sans-serif;
    font-size: 14px;
    line-height: 1.28571429;
}
.custom_form_control{
   
}
.status_p_label{
    color: #080f1a;
    font-family: euclidcircularb,sans-serif;
    font-size: 14px;
    line-height: 1.28571429;
}

.img_icon{
    width: 24px;
    text-align: end;
}
.display_buttons{
    display: flex;
}
.left_btn_size{
    width: 120px;
    background-color: #f7f8fc !important;

}
.left_btn_size:focus{
    border-bottom: 1px solid blue !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.btn_group_lg{
    border: 1px solid rgba(107, 98, 98, 0.356);
    border-radius: 0px;
}
.btn_position_size{
    color: #080f1a;
    font-family: euclidcircularb,sans-serif;
    font-size: 14px;
    line-height: 1.28571429;
}
.form-range{
    width: 60% !important;
}
.medium{
    margin-left: 94px !important;
}
.range_area{
    width: 66% !important;
    display: flex;
    justify-content: space-between;
}
.large{
    margin-right: 29px !important;
}
.display_display{
    display: flex;
}
.rowtocolumn{
    display: flex;
    flex-direction: column;
}
.question_mark{
    margin-top: -7px;
}
.email{
    margin-bottom: 0px !important;
    font-size: 14px !important;
}
.bg_email{
    background-color: #f7f8fc !important;
    padding: 10px;
}
.email_control{
    padding-left: 12px !important;
    margin-left: 5px !important;
    padding-top: 9px !important;
    width: 280px !important;
}
.permission{
    font-size: 13px !important;
   
    width: 280px;
}
.deleteiconwiget{
    font-size: 25px;
    margin-left: 10px;
    color: gray;
}
.checkbox_icon{
    font-size: 14px !important;
   
}
.delete_btn{
    padding-left: 12px !important;
    margin-left: 5px !important;
}
.delete_padding{
    padding-left: 17px !important;
}
.width_add{
    width: 70% !important;
}
.custom_add{
    margin-left: 12px !important;
    font-size: 11px !important;
}
.custom_width{
    width: 74.666667% !important;
}
.groupbtndata{
    width: 240px;
}
.widget_div{
    box-shadow: 5px 5px 10px rgba(23, 58, 70, 0.288);
   padding-bottom: 10px !important;
   border-radius: 10px;
   
}
.iconofwidget{
    color: gray;
    font-size: 20px;
}
.background_img{
    background-image: url(../../../../images/chat_back.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   clip-path: ellipse(98% 95% at 42% 5%);

}
.hrim{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.apraforofline{
    font-size: 18px;
    margin: 0px 0px;
    padding: 10px;
    text-align: center;
}
.transdata{
    display: flex;
    flex-direction: column;
    height: 20vh;
}
.transdata input{
    width: 75%;
    margin: 10px auto ;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: none;
}
.transdata button{
    width: 75%;
    margin: auto;
}
 .img_color{
    background-color: rgba(33, 113, 150, 0.664);
    width: 100%;
    height: 100%;
   display: flex;
   flex-direction: column;
    top: 0;
    z-index: -2;
} 
.backdivofacard{
    border: 1px solid rgba(161, 168, 161, 0.253);
    border-radius: 10px;
    overflow: hidden;
}
.hitheretext{
    padding: 15px;

}
.hitheretext h5{
    font-weight: 400;
    color: white;
    font-size: 22px;
    line-height: 22px;
    overflow-wrap: break-word;
    white-space: pre-line;
    -webkit-text-stroke: 0.4px;
    display: flex;
    align-items: center;
}
.iconmainduv{
    padding-left: 0px;
    color: white;
    display: flex;
    font-size: 25px;
    align-items: center;
}
.messagetextcard{
    padding: 0px;
    margin-top: 5px;
    color: white;
}
.doticononline{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
}
.weimmediatex{
    margin-left: 10px;
    
}
.maindivofaimme{
    color: white;
    border-top: 1px solid rgba(211, 211, 211, 0.438);
}
.sometextmessages{
    height: 160px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.288);
}
.indiv input{
    border: none;
    width: 100%;
    border-top: 1px solid rgba(211, 211, 211, 0.397);
    height: 62px;
    outline: none;
    padding: 10px;
}
.emailtext{
    display: flex;
    font-size: 16px;
    padding: 5px;
    width: 85px;
}
.askvisitor{
    font-size: 12px;
}
.inputsurvey{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: 1px solid lightblue;
    height: 35px;
    font-size: 16px;
    width: 100%;
}
.inpdivforwit{
    width: 265px;
}
.inputsurvey1{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: 1px solid lightblue;
    height: 35px;
    font-size: 16px;
}

.Create_right{
    flex-direction: column;
}
.user_width{
    width: 96px !important;
    border-radius: 20%;
}
.chat_setting_profile{
    width: 90px !important;
}
.arrow{
    padding: 12px !important;
    border-radius: 0px !important;
}
.custom_control:focus{
    box-shadow: none !important;
    border-color: gainsboro !important;
}
.check_label{
    padding-left: 12px;
}
.block_btn{
    width: 100% !important;
    background-color: #1c8681 !important;
    border: none !important;
    border-radius: 5px !important;
    font-size: initial !important;
}
.block_btn:hover{
   background-color: #10524e !important;
}
@media only screen and (min-width: 370px) and (max-width: 600px) {
    .display_display{
        display: flex;
        flex-direction: column;
    }
    .emailinputandtext{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .emailtext{
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 5px;
        width: 100%;
        justify-content: center;
    }
    .widget_div{
        flex-direction: column;
    }
    .width_add{
        width: 100% !important;
    }
  
}
.back_image{
    width: 76px !important;
}
