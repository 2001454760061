.firstText {
  color: #080f1a;
  line-height: 64px;
  font-size: 52px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
}

.nextText {
  color: #080f1a;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.pricing-button {
  text-align: center;
}

.pricing-button .btn-primary {
  color: #ffffff;
  line-height: 32px;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  border: none;
  width: 25%;
  outline: none;
  padding: 10px;
  background-color: #0566ff;
  border-radius: 5px;
}

.pricing-button .btn-primary:hover {
  background-color: #080f1a;
}

.pricing-tabs {
 
  border: 1px solid rgba(211, 211, 211, 0.623);
  background-color: white;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
 
}
.nav-fill .nav-item, .nav-fill>.nav-link{
  border-radius: 15px;
  
  margin-top: 10px;
}


.pricing-annuallyFree {
  color: #080f1a;
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.pricing-cards {
  background: #f5f7f9;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
}

.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 100vh;
  margin: 1rem;
  position: relative;
  width: 100%;
}

.card-1 {
  padding: 45px;
 height: 130vh !important; 
}

.first-cardText {
  color: #080f1a;
  line-height: 32px;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
}

.cardText2 {
  color: #647491;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.card-price {
  color: #080f1a;
  line-height: 64px;
  font-size: 52px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.pricing-price {
  color: #647491;
  /* line-height: 16px; */
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
}

.card-price .currency {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #647491;
  font-weight: 400;
  position: absolute;
  padding-left: 6px;
}

.card-price .perMonth {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #647491;
  font-weight: 400;
  position: relative;
  top: -2px;
  padding-left: 5px;
}

.trialButton {
  text-align: center;
  width: 100%;
}

.trialButton .card-trial-button {
  outline: none;
  font-style: normal;
  font-weight: 500;
  color: #080f1a;
  line-height: 23px;
  font-size: 18px;
  padding: 10px;
  background-color: #fff;
  border: solid 2px #eff2f6;
  width: 80%;
  border-radius: 5px;
}

.trialButton .card-trial-button:hover {
  background-color: #080f1a;
  color: #e2e1e0;
}

.buttonBottom-text p {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: #647491;
  line-height: 18px;
  text-align: center;
}

.secondLast-text ul li {
  list-style: none;
  border-bottom: 2px dotted #c4cfe2;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding: 5px 0px;
}

#highlightText {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 18px;
}

.secondLast-text ul li::before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f5f7f9;
  background-image: url(/src/images/dodgerblue-check.svg);
  -webkit-background-size: 14px 14px;
  background-size: 14px 14px;
  -webkit-background-position: 50%;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  left: 30px;
}

@media (min-width: 905px) {
  .secondLast-text ul li:before {
    width: 19px;
    height: 19px;
    -webkit-background-size: 12px 12px;
    background-size: 12px 12px;
  }
}

.trialButton .card-trial-button2 {
  outline: none;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  line-height: 23px;
  font-size: 18px;
  padding: 10px;
  background-color: #0566ff;
  border: solid 1px #eff2f6;
  width: 80%;
  border-radius: 5px;
}

.trialButton .card-trial-button2:hover {
  background-color: #080f1a;
  color: #e2e1e0;
}

.features p {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  color: #647491;
  line-height: 18px;
  font-size: 14px;
}
.questions-first {
  font-size: 52px;
  color: #080f1a;
  font-weight: 600;
  font-style: normal;
  line-height: 64px;
  text-align: center;
}
.frequentlymaindiv{
  width: 50%;
  min-height: 100vh;
}

.accordion-item {
  border: none !important;
}
.accordion-body {
  color: #080f1a !important;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  font-style: normal;
}
.accordion-button {
  color: #0566ff !important;
  font-weight: 500;
  white-space: pre-line;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin: 0;
  font-weight: 500;
}
.accordion-button:not(.collapsed)::after {
  background-image: var();
  transform: var(--bs-accordion-btn-icon-transform);
  content: "";
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var();
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
#cardsRow {
  width: 98%;
  display: flex;
  justify-content: space-around;
}
.both {
  position: relative;
  top: 10%;
  left: 35%;
  width: 30%;
}
#firstContainer {
  height: 40vh;
}
.tabs{
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
}
.tabsmon{
  border: 1px solid lightblue;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}
.tabsmon-active{
  border: 1px solid lightblue;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  background-color: rgba(33, 33, 179, 0.897);
  color: white;
}
@media only screen and (max-width: 890px) and ( min-width: 500px ) {
  .firstText{
    line-height: 50px;
    font-size: 45px;
  }
  .pricing-button .btn-primary{
    width: 40%;
    line-height: 24px;
    padding: 13px;
  }
  .both{
    left: 34%;
  }
  .card-1 {
    padding: 45px;
    overflow-y: scroll;
   height: 100vh !important; 
  }
}
@media only screen and (max-width: 1024px) and ( min-width: 890px ) {
  .firstText{
    line-height: 50px;
    font-size: 52px;
  }
  .pricing-button .btn-primary{
    width: 40%;
    line-height: 24px;
    padding: 13px;
  }
  .both{
    left: 40%;
  }
}
@media only screen and (max-width: 500px) and ( min-width: 325px ) {
  .firstText{
    line-height: 40px;
    font-size: 41px;
  }
  .pricing-button .btn-primary{
    width: 70%;
    line-height: 24px;
    padding: 13px;
    font-size: 18px;
  }
  .both{
    left: 24%;
    width: 54%;
  }
  .card-1 {
    padding: 45px;
    overflow-y: scroll;
   height: 100vh !important; 
  }
  .frequentlymaindiv{
    width: 100%;
    min-height: 100vh;
    
  }
  .questions-first{
    font-size: 40px;
  }
}