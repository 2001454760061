.btncloose{
    border: 1px solid rgb(211, 211, 211);
    width: 70px;
    padding: 3px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background-color: gray;
    color: white;
    
}
.btnsaave{
    border: 1px solid lightgray;
    width: 70px;
    padding: 3px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background-color: rgb(0, 153, 255);
    color: white;
    
}
.btncloose:hover{
   background-color: darkslategray;
   cursor: pointer;
}
.btnsaave:hover{
    background-color:  rgb(17, 82, 126);
    cursor: pointer;
}
