.chat-area{
    background: linear-gradient(3deg, rgba(17, 17, 20, 0.082) 0%, rgba(105, 112, 105, 0.082) 99%);
    padding: 20px;
height: 60vh;
overflow-y: scroll;
margin-left: 0px !important;
}
.chat-area2{
    background: linear-gradient(3deg, rgba(17, 17, 20, 0.082) 0%, rgba(105, 112, 105, 0.082) 99%);
    padding: 20px;
height: 35vh;
overflow-y: scroll;

margin-left: 0px !important;
}
.chat-area::-webkit-scrollbar{
    width: 7px;
    border-radius: 5px;
    background-color: lightgray;
    
}
.chat-area::-webkit-scrollbar-thumb{
    background: linear-gradient(3deg, rgb(105, 105, 109) 0%, rgb(88, 82, 104) 99%);
    border-radius: 5px;
}

.imageuserfullsrc{
    width: 100px;
    height: 100px;
    cursor: pointer;
}
.imageuserfullsrc2{
    position: absolute;
    top: 10%;
    left: 30%;
    width: 550px;
    height: 440px;
}
.chat_icon{
    width: 29px;
}
.chat_row{
    display: flex;
    /* margin-top: 9px !important; */
}
.chat_name{
    font-size: 15px;
    padding-left: 15px;
}
.chat_time{
    color: black;
    font-size: 11px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.bcakhover{
    display: flex;
    text-align: left;
}

.bcakhover2{

}
.icondivchat{
    background-color: rgba(0, 183, 255, 0.541);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}
.icondivchat p{
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: bold;
}
.bcakhover:hover{
    background-color: rgba(211, 211, 211, 0.623);
    border-radius: 5px;
}
.bcakhover2:hover{
    background-color: rgba(211, 211, 211, 0.623);
    border-radius: 5px;
}
.chat_text{
    /* margin-left: 43px;
    margin-top: 0px; */
    width: fit-content;
    
    /* background-color: rgba(121, 121, 124, 0.233); */
    color: black;
    /* border-radius: 5px;
    padding: 12px; */

}
.chat_text p{
   margin: 0px;
   padding: 0px;
}
.chat_end_text{
    margin-right: 43px;
    margin-top: 0px;
    /* padding: 9px; */
    /* background: rgba(121, 121, 124, 0.233); */
    color: black;
    /* border-radius: 5px; */
    width: fit-content;
    display: flex;
    justify-content: end;
}
.chat_end_row{
    margin-right: 43px;
}
.boldemail{
    font-weight: bold;
    font-size: 14px;
}
.chat_end_text p{
   margin: 0px;
   padding: 0px;
}
.chat_end_row{
    display: inline-flex;
    
}
.chat_end_icon{
    width: 29px;
}
.chat_end_name{
    font-size: 15px;
    padding-left: 0px;
    padding-right: 15px;
}
.chat_end_time{
    color: black;
    font-size: 11px;
    padding-top: 2px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.text_area_padding{
    width: 100%;
    
}
.replybtn{
    padding: 20px 10px;
}
.custom_fluid{
   padding: 0px !important;
}
.bsdotonli{
   
}
.dottttedicon{
   color: rgba(128, 128, 128, 0.849);
    font-size: 14px;
    display: flex;
    justify-content: center;
 }
 .dottttedicon::before{
    content: "----------------------------------------------------  ";
    color: rgba(128, 128, 128, 0.534);

   letter-spacing: -3px;
   margin: 0px 3px;
 }
 .dottttedicon::after{
    content: " ----------------------------------------------------  ";
   color: rgba(128, 128, 128, 0.479);
   letter-spacing: -3px;
   margin: 0px 3px;

 }
 .mesagecontext{
     font-size: 14px;
 }
.custom_text_area{
    width: 100%;
    padding: 27px;
    border-radius: 5px;
    border: none;
    outline: none;
}
.replymaindic{
    border: 1px solid rgba(211, 211, 211, 0.288);
    padding: 0px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    width: 99% !important;
}
.openprofinfo{
    padding: 0px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #63646441;
    margin-left: 10px;
   
    
}
.openprofinfo2{
    padding: 0px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: rgb(0, 0, 0);
   
    background-color: #63646446;
    margin-left: 10px;
}
.openprofinfo2:hover{
    background-color: #63646493;
}
.openprofinfo:hover{
    background-color: #636464ab;
}
.header_chat{
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    transition: .7s;
    background-color: rgba(211, 211, 211, 0.342);
}
.callicon{
    border: 1px solid lightgray;
    font-size: 20px;
    border-radius: 5px;
    padding: 3px;
    color: blue;
    cursor: pointer;
    background-color: rgba(179, 185, 187, 0.322);
    width: 40px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.callicon:hover{
    background-color: rgba(179, 185, 187, 0.5);
}
.display_header{
    display: flex;
}
.online{
    margin-left: 8px;
    margin-bottom: 0px !important;
    padding: 0px;
}
.videocalldiv{
    border-radius: 5px;
    width: 100%;
    height: auto;
}
.videodivforcall{
    width: 550px;
    height: auto;
}
.headerofmodal{
    background: linear-gradient(313deg, rgba(58,180,80,1) 0%, rgba(0,0,0,0.4150035014005602) 0%, rgba(195,2,2,0.4150035014005602) 82%);

}
.modal-content{
    width: 100% !important;
}
.callbtn{
    background-color: rgb(7, 5, 143);
    color: white;
    border-radius: 5px;
    border: none;
    width: 80px;
    height: 40px;
    font-family: sans-serif;
    font-size: 20px;
    outline: none;
    padding: 5px;
    transition: .5s;
    margin-right: 5px;
}
.answerbtn{
    background-color: rgb(17, 141, 6);
    color: white;
    border-radius: 5px;
    border: none;
    width: 80px;
    font-family: sans-serif;
    font-size: 20px;
    outline: none;
    padding: 5px;
    transition: .5s;
    cursor: pointer;
}
.filetype{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.attacth{
    font-size: 20px;
    overflow: hidden;
    position: relative;
}
.backgorundmessdage{
    background-color: #F8F8F8;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    
}
.chatib{
    padding: 0px 0px 0px 12px;
}
.backgorundmessdage2{
    background-color: #F8F8F8;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    
}
.sendandreceice{
    white-space: nowrap;
    animation: showhide 2s ease-in-out infinite;
}
@keyframes showhide {
    from{
       opacity: .3;
    }
    to{
        opacity: 1;

    }
}
.videoCallbtnandimg{
    display: flex;
    justify-content: center;
    align-items: center;
}
.imagetopcrossdiv{
    position: absolute;
    top: 10%;
}
.imagetopcrossdiv img{
    width: 560px;
    height: 490px;
}
.btncrostop{
    position: absolute;
    top: 10px;
    right: 2%;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    background-color: rgb(24, 185, 9);
    border-radius: 5px;
}
.replyandatach{
    display: flex;
    justify-content: end;
    width: 100%;
}
.backimagesnoimg{
    width: 105%;
    height: 60vh;
}
.callbtn:hover{
    background-color:rgb(5, 143, 28) ;
    transition: .5s;
}
.mutedbtn{
    background-color: rgb(7, 5, 143);
    color: white;
    border-radius: 5px;
    border: none;
    width: 80px;
    font-family: sans-serif;
    font-size: 20px;
    outline: none;
    transition: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 9px;
    margin: 5px 5px 5px 0px;
}
.mutedbtn:hover{
    background-color:rgb(5, 143, 28) ;
    transition: .5s;
}
.headback{
    background: #97b0e4 !important;
    color: white;
    font-style: italic;
    text-shadow: 1px 1px 5px black;
}
.modalcall{
 
}
.rowofvideo{
    background: linear-gradient(90deg, rgba(53, 96, 180, 0.678) 0%, rgba(255, 253, 253, 0.623) 64%);
}
.videofulldiv{
    margin: auto;
    display: flex;
}
.maindottttedicon{
    display: block;
}
.smalldottttedicon{
    display: none;
}
@media only screen and (max-width: 800px) and (min-width: 400px)  {
    .chat_end_text{
        margin-right: 10px;
    }
    .chat_end_row{
        margin-right: 10px;
    }
    .backgorundmessdage2{
       display: none;
        
    }
    .backgorundmessdage{
        background-color: #F8F8F8;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        font-style: italic;
        height: 60vh;
    }
    .smalldottttedicon{
        display: block;
        font-size: 12px;
color: gray;
margin-left: 5px;
    }
    .maindottttedicon{
        display: none;
    }
}