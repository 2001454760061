*{
    margin: 0px;
    padding: 0px;
    text-decoration: none;
}
.custom_container{
    display: flex;
}
main{
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    overflow: hidden;
}
.sidebar{
    background-color: #1C2B45;
    color: white;
    height: 100vh;
    width: 300px;
    transition: .7s;
    z-index: 1;
    position: sticky;
    top: 0;
}
.top_section{
    display: flex;
    align-items: center;
    padding: 20px 15px;
}
.fabricon{  
    cursor: pointer;
}
.closecrass{
    cursor: pointer;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
}
.custom-cross{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: 0.5s;
    width: 20px;
    height: 20px;
    border-radius: 5px;
}
.custom-cross:hover{
   transform: rotate(90deg);
   
   box-shadow: 1px 1px 5px white;
}
.cro2{
    background-color: white;
    width: 20px;
    height: 2px;
    margin-bottom: -12px;
    transform: rotate(45deg);
    cursor: pointer;
    position: relative;
    top: 43%;
}
.cro3{
    background-color: white;
    width: 20px;
    height: 2px;
    margin-top: 19px;
    transform: rotate(-45deg);
    cursor: pointer;

}
.logo{
    font-size: 30px;
}
.bars{
    font-size: 25px;
    display: flex;
    margin-left: 50px;
}
.link{
    display: flex;
    color: #ffff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
  position: relative;
}
.link_text{
    visibility: hidden;
    margin-top: 10px ;
    padding-bottom: 12px;
    white-space: nowrap;
    font-family: sans-serif;
    position: absolute;
    top: 10%;
    left: 85%;
    background-color: rgb(0, 132, 255);
    padding: 5px;
    letter-spacing: 1px;
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;
    z-index: 5;
}
.iconsid{
    font-size: 25px;
    color: lightgray;
    opacity: .5;
}

.iconactive{
    font-size: 25px;
    color: white;
    opacity: 1;
}

.link:hover .link_text{
    visibility: visible;
    color: white;
    transition: all 0.5s;
}

@media only screen and (min-width: 375px) and(max-width: 600px) {
    main{
        width: 100%;
        padding-left: 14px;
        padding-right: 20px;
        padding-bottom: 5px;
    }
}