.chatcardmaindiv{
    width: 370px;
    height: 75vh;
    border-radius: 20px;
  
    position: absolute;
    top: 10%;
    left: 10%;
    background-color: white;
    border: 1px solid lightgray;
}
.cardheader{
   border-radius: 10px 10px 0px 0px;
    background-color: blue;
    width: 100%;
    height: 90px;
}
.cardchatimg{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.iconchathead{
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 4px;
    color: white;
    font-weight: bold;
}
.iconchathead2{
    display: flex;
    align-items: center;
    font-size: 30px;
    padding: 4px;
    color: white;
    font-weight: bold;
}
.greetinghand{
    color: yellow;
    margin-left: 6px;
}
.maintextchathead{
    width: 280px;
    display: flex;
    justify-content: space-between;
}
.dotonline{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
}
.online{
    font-size: 18px;
    
    padding: 3px;
    margin-left: 5px;
}
.weareon{
    background-color: rgba(172, 255, 47, 0.445);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}
.textparadiv{
    border: 1px solid lightgray;
    border-radius: 20px;
    padding: 6px;
    width: 260px;
    background-color: rgba(211, 211, 211, 0.192);
}
.spanreply{
    
    padding: 5px;
    border-radius: 10px;
    background-color: blue;
    color: white;
}
.spanreplymain{
    display: flex;
    justify-content: end;
    padding: 0px 10px;
}

.messagestext{
    height: 300px;
    border-bottom: 1px solid lightgray;
}
.textyourmessage{
    border: none;
    outline: none;
    padding: 10px;
    color: blue;
    width: 100%;
    margin: 0px 10px;
}
.sendmessageicon{
    font-size: 45px;
    background-color: blue;
    color: white;
    padding: 5px;
    border-radius: 50%;
    position: relative;
    top: 0%;
    left: 3%;
}
.smileicon{
    font-size: 30px;
    
}