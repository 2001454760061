.subbil{
    margin: 20px 0px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.623);
}
.modalinputt{
    border: 1px solid lightgray;
    width: 99%;
    border-radius: 0px;
    padding: 10px;
    margin: 5px 2px;
    
}
.billing-div{
    box-shadow: 1px 1px 10px lightgray;
    margin-top: 20px !important;
    padding: 20px;
    border-radius: 5px;
}

.modalinputt:focus{
    outline: 1px solid rgb(20, 194, 252);
    border: 0px solid black;
 }
 .modalinputt2:focus{
    outline: 1px solid rgb(20, 194, 252);
    border: 0px solid black;
 }
.modalinputt2{
    border: 1px solid lightgray;
    width: 49.14%;
    border-radius: 0px;
    padding: 10px;
    margin: 5px 2px;
}