.ProfileChatMaindiv{
    width: 270px;
    height: 90vh;
    border: 1px solid lightgray;
    background: linear-gradient(3deg, rgba(17, 17, 20, 0.082) 0%, rgba(105, 112, 105, 0.082) 99%);
    animation: profinfoanim 1s linear;
    transition: linear 1s;
}
@keyframes profinfoanim {
    from{
       opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.imageofuser{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.subdetial{
    padding: 5px;
    border-bottom: 1px solid lightgray;
}
.subinnerdiv{
    background-color: white;
   position: absolute;
   left: 15px;
}
.subscribetext{
    position: relative;
      text-align: center;
      font-size: 14px;
      margin-right: 43px;
  }
.subscribetext  span{
    border: 1px solid lightgray;
    border-radius: 5px;
}
.useraddname{
    font-size: 12px;
    color: rgb(0, 0, 255);
}
.addressofuser{
    font-size: 13px;
    color: gray;
    padding: 0px 5px 0px 0px;
}
.profileicon{
    padding: 3px 0px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}
.profileicon span:hover{
   color: rgb(0, 0, 255);
}
.profmainicondiv{
    margin: 0px 10px 10px 10px ;
    
}
.profBoxdta{
    margin: 8px 0px;
    color: gray;
    
}

.crossdata{
    margin-left: 20px;
  
}
.questionicon{
    font-size: 80px;
    color: rgba(187, 5, 5, 0.623);
    margin: 10px 0px;
}
.maindivofcrossmodal{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.crossdataphon{
    margin-left: 70px;
  cursor: pointer;
}
.crossdata:hover{
    color: blue;
    cursor: pointer;
}
.profBoxdta span:hover{
    
    color: blue;
}
.profBoxdta2{
    margin: 5px 0px;
    background-color: white;
    border-radius: 4px;
    height: 270px;
    padding: 5px;
    font-size: 18px;
}
.firstnamemodal{
    padding: 5px;
    margin: 10px;
}
.firstnamemodal label{
   
    margin-right: 10px;
}
.secondnamemodal label{
   
    margin-right: 10px;
}
.secondnamemodal{
    padding: 5px;
    margin: 10px;
}
.profBoxdta3{
    margin: 5px 0px;
    background-color: white;
    border-radius: 4px;
    height: 160px;
    padding: 5px;
    font-size: 18px;
}
.profBoxdta p {
    font-size: 12px;
}
.te{
    font-size: 14px;
}
.useremail{
    font-size: 14px;
}
.lastvieweddiv{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.see{
    font-size: 12px;
    border: 1px solid lightgray;
    padding: 3px;
    border-radius: 5px;
    color: gray;
}
.lastviw{
    font-size: 16px;
}
.personlineicon{
    color: gray;
}
.personlinkshareicon{
    font-size: 30px;
    color: gray;
}
.listviewlnk{
    font-size: 14px;
    padding: 5px;
}
.firstnamemodal input{
    border: 1px solid lightblue;
    border-radius: 8px;
}
.secondnamemodal input{
    border: 1px solid lightblue;
    border-radius: 8px;
}