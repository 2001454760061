.home_link{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 20px;
    width: 100%;

}
.home_link .home {
gap: 1rem;
}
.register{
    display: flex;
    justify-content: end;
}
.logout{
    width: 90px;
    height: 45px;
    border: 1px solid lightgray;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    background-color: rgb(0, 0, 255);
    color: white;
    border-radius: 5px;
}
.logout:hover{
    cursor: pointer;
  background-color: black;
}
.sticky-nav{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background-color: white;
}
.home{
    font-size: 18px !important;
    line-height: 23px !important;
    letter-spacing: -0.01em !important;
    color: #080f1a !important;
    text-decoration: none;
}
.reg_padding{
    padding: 8px 24px !important;
    width: 110px;
    border: 2px solid #EFF2F6 !important;
    background-color: white !important;
}
.reg_padding:hover{
    background-color: #080f1a !important;
    color: white !important;
}
.navLinkdiv{
    position: relative;
    width: 100%;
    display:  flex;
    justify-content: space-between;
    background-color: white;
}
.go_to_app{
    width: 190px  !important;
}
@media only screen and (max-width: 768px) and (min-width: 300px)  {
    .home_link{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        margin-right: 0px;
    }
    .register{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
 
}
