.profile-maindiv{
    width: 280px;
    height: 88vh;
    background-color: #1C2B45;
    position: absolute;
    right: 0%;
    top: 100%;
    z-index: 10;
    border-radius: 5px;
    animation: profilemai .7s linear;
    transition: .5s !important;
    overflow: hidden;
}

.profile-maindiv2{
    width: 0px;
    height: 88vh;
    overflow: hidden;
    background-color: #1C2B45;
    position: absolute;
    right: 0%;
    top: 100%;
    z-index: 10;
    border-radius: 5px;
    white-space: nowrap;
    word-wrap: normal;
    animation: profilemai .7s linear;
    transition: .5s !important;
}

@keyframes profilemai {
    from{
       right: -30%;
    }
    to{
        right: -1%;
    }
}
.selectlangua{
    background-color: #1C2B45;
    font-size: 12px;
    color: white;
    border-radius: 5px;
    outline: none;
    border: none;
    text-transform: uppercase;
}
.dataslectdiv{
    border: 1px solid rgba(211, 211, 211, 0.603);
    color: white;
    border-radius: 4px;
    padding: 3px;
    white-space: nowrap;
}
.crossicon{
    color: white;
    font-size: 25px;
    cursor: pointer;
    display: flex;
    justify-content: end;
    align-items: center;
}
.profileimagediv{
    display: block;
    margin: auto;
    white-space: nowrap;
}
.profileimage{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: auto;
    display: block;
}
.selectedata{
    width: 140px;
    height: 35px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    margin: auto;
    color: white;
    font-size: 14px;
    background-color: rgba(8, 8, 78, 0.144);
}
.selectedata2{
    width: 140px;
    height: 35px;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background-color: rgb(23, 23, 61);
}
.usericonTExt{
    display: flex;
    color: white;
    margin-top: 15px;
    justify-content: space-between;
    width: 160px;
    margin: auto;
}
.usericon{
    font-size: 25px;
    
}
.usericon2{
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-self: center;
}
.userProfiletext{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
    width: 80px;
}
.userProfiletext p{
    font-size: 14px;
}
.logprosupbtn{
    margin: 40px 20px;
}
.textdata{
    color: rgba(255, 255, 255, 0.884);
    white-space: nowrap;
}
.userProfiletext:hover{
    cursor: pointer;
}