.inputforget{
    border-top: 8px solid rgb(17, 3, 141);
    border-bottom: 8px solid rgb(2, 4, 151);
    border-left: 0px solid rgb(3, 255, 242);
    border-right: 0px solid rgb(3, 255, 242);
    width: 400px;
    margin: auto;
    padding: 40px;
    height: 40vh;
    border-radius: 10px;
    box-shadow: 4px 4px 20px rgba(102, 101, 98, 0.274);
}
.forrpass{
    text-shadow: 1px 1px 5px rgb(18, 0, 97);
    color: white;
}
.subbtn{
    border: 1px solid lightgray;
    border-radius: 8px;
    
    padding: 5px 20px;
    margin: 10px;
    background-color: rgb(3, 49, 255);
    color: white;
   font-size: 20px;
    font-family: serif;
}
.subbtn:hover{
    background-color: rgb(3, 20, 97);
}
.indata{
    box-shadow: 1px 1px 5px rgba(0, 26, 255, 0.384);
}