
.sometext{
    width: 700px;
    height: 500px;
    text-align: start;
    font-size: 40px;
    letter-spacing: 3px;
    line-height: 64px;
    color: rgba(255, 255, 255, 0.863);
    font-weight: 700;
    padding: 40px;
    position: relative;
    top: 6%;
    left: 10%;
}
.customer_service{
    font-size: 52px;
    line-height: 64px;
    letter-spacing: -0.03em;
}
.get_started{
    height: fit-content;
    min-height: 52px !important;
    padding: 10px 32px !important;
    background: #0566ff !important;
    border: 2px solid #0566ff !important;
    color: #ffffff !important;
    pointer-events: unset !important;
    text-align: center !important;
    min-width: 220px !important;
    font-weight: 500 !important;
    border-radius: 3px !important;
}
.get_started:hover{
    border: 2px solid #001b47 !important;
    background-color: #001b47 !important;
}
.tidio_combines{
    color: rgba(255, 255, 255, 0.705);
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    font-size: 40px;
    line-height: 64px;
    letter-spacing: -0.02em;
    font-weight: 500;
    animation: tidoiconbine 1s linear;
    font-family: euclidcircularb,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
@keyframes tidoiconbine {
    0%{
        padding-right: 4rem;
    }
    100%{
        padding-right: 0px;
    }
}
.bg_tidio{
    background-color: #001b47;
    color: white;
    height: auto;
}
.chatimage{
    display: flex;
    justify-content: center;
}
.tidio_section_margin{
    margin-top: 130px !important;
}

.wisker{
    padding: 137px;
    font-family: monospace;
    font-size: 15px;
}
.by_using{
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.01em;
}

.melisa{
    height: 62px !important;
    width: 29% !important;
}
.display_card{
    display: flex;
}
.card_img{
    width: 20%;
}
.display_img{
    display: flex;
}
.name_card{
    margin-left: 28px;
    margin-top: 6px;
}
.service{
    font-size: 21px;
    font-weight: 500;
}
.tidio_heading{
    margin-top: 6px;
    font-weight: 700;
    font-size: 21px;
    letter-spacing: -0.02em;
}
.chat_pic{
    max-width: 45% !important;
   
}
.second_row{
    margin-top: -84px;
}
.second_card{
    margin-top: 35px;
}
.cards_row{
    margin-top: 135px !important;
    display: flex;
    justify-content: center;
}
.A_power{
    font-weight: 600;
    font-size: 52px;
    line-height: 64px;
    letter-spacing: -0.03em;
}
.one_p{
    font-weight: 400;
    font-size: x-large;
}
.live_chat{
    font-size: 40px;
    line-height: 52px;
    font-weight: 600;
    letter-spacing: -0.02em;
}
.half_of{
    font-weight: 600;
    margin-top: 28px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
}
.ul_padding{
    padding-left: 20px !important;
}
.connect_li{
    margin-top: 22px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    font-weight: 400;
}
.discover_live{
    font-size: larger;
    text-decoration: none;
    font-weight: 500;
    min-width: auto;
    height: auto;
    color: #0566ff;
    display: inline-block;
}
.discover_live:hover{
    border-color: #0566ff;
    font-weight: 500;
    font-size: larger;
    text-decoration: underline;
    cursor: pointer;
}
.live_chat_padding{
    padding: 90px !important;
}
.img_live_chat_padd{
    padding-top: 40px !important;

}
.excellenceicon{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}
.excetext{
    font-size: 50px;
    font-weight: bold;
}
.icondif{
    font-size: 25px;
    margin: 20px 10px;
    font-weight: bold;
}
.imgview{
    position: relative;
    left: 30%;
}
.bag_img{
    position: relative;
    top: -32%;
    left: -26%;
}
.imgsldie{
   z-index: -1;
    height: 680px;
}
.line{
    width: 2px;
    border: 1px solid black;
    height: 180px;
    background-color: black;

}
.horseimgage{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.carouseldiv{
    position: absolute;
}
.carolimagemain{
    width: 500px;
    height: 800px;
    
}
.informationofcard{
    margin-top: 30px;
   
}
.whiskerbagdiv{
   animation: whiskerbaganim 1s linear;
}
@keyframes whiskerbaganim {
    0%{
        padding-left: 8rem;
    }
    100%{
        padding-left: 0px;
    }
}
.carousellineimagediv{
   
    position: relative;
    margin-top: -15px;
    width: 600px;
}
.carouselinformationduv{
    position: relative;
    left: 25%;
    padding: 20px;
    height: 600px;
    
    
}
.carousel-control-next-icon, .carousel-control-prev-icon{
    background-color: black;
    
}
.carousel-indicators [data-bs-target]{
    display: none;
}
.liv_col{
    padding-left: 50px;
}
.virtual_padd{
    margin-top: 50px !important;
}
.more_sales{
    margin-top: 81px !important;
}
.textparagraph{
    width: 40%;
    
}
.whiskerName{
    letter-spacing: 7px;
    font-weight: bold;
}
.card_shadow{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    padding: 10px;
}
.cardmaindivmarque{
    margin: 10px 0px;
    width: 350px !important;
    background-color: white;
   
}
/* .cardmaindivmarque:hover{
   
    
    background-color: white;
    animation: cardmove 15s linear infinite;
    transition: 1s;
} */

@keyframes cardmove {
    0%{
        transform: rotate(10deg);
       transition: 1s;
    }
    100%{
        transform: rotate3d(60deg, 30deg);
      transition: 1s;
    }
}

@media only screen and (max-width: 890px) and (min-width: 350px)  {
    .carouseldiv{
        position: relative;
    }
    .live_chat_padding{
        padding: 0px !important;
    }
    .cardmaindivmarque{
        
        width: 350px !important;
        
    }
    .cards_row{
        justify-content: center;
       
    }
    .imgsldie{
        width: 470px !important;
        display: none;
    }
    .carouselinformationduv {
        position: relative;
        left: 10%;
        padding: 0px;
        height: 470px;
    }
    .carousellineimagediv{
        display: none;
    }
    .textparagraph{
        width: 85%;
        
    }
    .cardlinetextdiv{
       margin-left: 20px;
    }
    .whiskerbagdiv{
   
        width: 100%;
        padding: 5px;
        margin-top: 60px;
        margin-left: 0px;
    
    }
    .whiskerName{
        letter-spacing: 5px;
    }
    .informationofcard{
        margin-top: 0px;
        height: 470px;
    }
    .customer_service{
        font-size: 40px;
    }
    .A_power{
        font-size: 40px;
    }
    .live_chat{
        font-size: 34px;
    }
    .rowreverse{
        flex-direction: column-reverse;
    }
    .excellenceicon{
        justify-content: space-around;
    }
}
