.user_img{
    width: 28px;
    cursor: pointer;
    border-radius: 20px;
    
}
.statsicon{
    font-weight: bold;
    font-size: 25px;
}
.naviconbar{
    width: 170px;
    display: flex;
    justify-content: end;
    align-items: center;
}
.backtohome{
    cursor: pointer;
    border-radius: 5px;
    padding: 0px 5px;
    font-family: monospace;
    font-weight: bold;
}
.backtohome:hover{
    background-color: rgba(0, 47, 255, 0.253);
}
.upgradebtn{
    background-color: white;
    border: 1px solid #ff5100;
    border-radius: 5px;
    color:  #ff5100;
    padding: 6px;
    font-weight: bold;
    font-size: 14px;
 }
 .upgradebtn:hover{
    background-color:  #ff5100;
    border: 1px solid  #ff5100;
    color: white;
    border-radius: 5px;
    padding: 6px;
 }
 .textvalue{
    font-size: 14px;
    color: rgb(11, 241, 11);
    cursor: pointer;
}
.textvalue2{
    font-size: 14px;
    color: rgb(152, 153, 152);
    cursor: pointer;
}
 .dashbdicon{
     margin-right: 5px;
     font-size: 25px;
     color: gray;
 }