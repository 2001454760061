.maindivofregister{
    height: 100vh;
}
.inerdivreg{
    width: 100%;
        margin-top: 25px;
    animation: inerscroll 1s linear;
}
@keyframes inerscroll {
    0%{
      margin-top: -3rem;  
    }
    100%{
        margin-top: -0px;
    }
}
.inerdivreg p{
    display: flex;
    justify-content: center;
    color: gray;
    font-size: 16px;
}

.createtext{
    color: rgb(23, 43, 112);
    font-weight: bold;
    display: flex;
    justify-content: center;
    letter-spacing: 3px;
}
.inputdiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    width: 90%;
    margin: auto;
}
.inputfielddata{
    border: 1px solid lightgray;
    padding: 15px;
    margin: 5px;
    height: 45px;
    border-radius: 10px;
    font-size: 16px;
    width: 100%;
}
.checkbtext{
    display: flex;
    align-items: center;
}
.maincheckbox{
    display: flex;
    align-items: center;
   margin-top: 0px;
}
.agreetext{
    margin-left: 10px;
    font-size: 18px;
}
input.agreetext{
    transform : scale(1.5);
}
.agreetext2{
    margin-left: 10px;
    font-size: 18px;
   margin-top: 28px;
}
.btnreg{
    height: 45px;
    width: 80%;
    margin: auto;
}
.icondiv{
    border-radius: 5px;
    cursor: pointer;
}
.icondiv:hover{
    background-color: black;
    
}
.icon{
    font-size: 30px;
    border-radius: 50%;
    color: rgb(4, 151, 219);
}

.horseimg{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.registersidebartext{
    position: relative;
    top: 20%;
    left: 10%;
}
.backgroundmain{
    background-color: rgba(0, 0, 255, 0.041);
    clip-path: circle(99.2% at 1% 46%);
}
.fbgobtn{
    
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.auth_social{
    border: 1px solid gray;
    font-size: 16px;
    font-weight: 500;
    background-color: white;
    color: rgb(13, 132, 243);
    border-radius: 5px;
    outline: none;
    width: 150px;
    height: 50px;
}
.auth_social:hover{
    background-color: black;
    color: white;
}
.kep-login-facebook.metro{
    border: 1px solid gray;
    font-size: 12px;
    background-color: white;
    color: rgb(13, 132, 243);
    border-radius: 5px !important;
    outline: none;
    padding: 5px;
    width: 160px;
    height: 50px;
}
.registerbtn{
    display: flex;
    justify-content: center;
}
.kep-login-facebook.metro:hover{
    background-color: black;
    color: white;
}
@media only screen and (max-width: 766px) and (min-width: 400px)  {
    .backgroundmain{
        height: 50vh;
    }
    
}
@media only screen and (max-width: 700px) and (min-width: 325px)  {
    .backgroundmain{
        display: none;
    }
    .inputdiv{
        width: 100%;
    }
    .inerdivreg{
        width: 100%;
    }
    .mainregisterdiv{
        width: 90%;
    }
}