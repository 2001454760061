.trans_head{
    line-height: 64px !important;
    letter-spacing: -0.03em;  
    font-size: 40px !important;
    font-weight: bold !important;
}
.shadow_trans{
    border-radius: 2%;
 box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.54);
-webkit-box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.54);
-moz-box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.54);
}
.display_trans{
    padding-right: 0px !important;
    display: flex;
}
.trans_img{
    max-width: 53%;
}
.image-end{
    text-align: end;
}
.custom_free{
    padding: 19px !important;
}
.justify_btween{
    justify-content: space-around;
}
.view_pricing{
    font-size: x-large;
    font-weight: 700;
    text-decoration: none;
}
.view_pricing:hover{
    font-size: x-large;
    font-weight: 700;
    text-decoration: underline;
}
.A_day{
    font-size: 24px !important;
    line-height: 32px !important;
    letter-spacing: -0.02em !important;
}
.Discover_bg{
    background-color: #001B47;
}
.Discover_heading{
    color: white;
}
.padding_dis{
    padding: 180px !important;
    margin-bottom: 100px !important;
}
.Get_btn:hover{
    color: #080f1a !important;
    background: #ffc859 !important;
    border: 2px solid #ffc859 !important;
}
.Get_btn{
    width: 188px !important;
    border-radius: 0px !important;
    border: 2px solid #0d6efd !important;
}
.mainrowtranparantdiv{
   margin: 30px 0px 40px 0px !important;
}
.cardcustomertext{
   
    border-radius: 7px;
    overflow: hidden;
    width: 480px !important; 
    box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.2);
    
}
.imgtransparent{
    height: 260px;
    width: 190px;
}
.spantexttrans{
    padding: 25px;
}
.anchorfullpri{
    font-size: 20px;
    cursor: pointer;
    transition: .5s;
    font-weight: bold;
    color: rgb(4, 126, 240);
    width: 200px;
    margin: auto;
}
.anchorfullpri:hover .aroico{
    margin-left: 10px;
    transition: .5s;
}
.aroico{
    transition: .5s;
    font-weight: bold;
}
.seventrail{
    font-size: 25px;
    margin-bottom: 30px;
}
.dicovertiodio{
    color: rgba(245, 245, 245, 0.877);
    text-align: center;
    font-size: 50px;
    margin-top: 30px;
    font-weight: 500;
}
.dicoverbackdown{
    background-color: rgb(5, 26, 65);
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.startdebtn{
    background-color: rgba(14, 14, 255, 0.733);
    color: whitesmoke;
    padding: 15px 20px;
    border-radius: 10px;
    border: none;
    width: 190px;
    margin-bottom: 30px;
   }
   .startdebtn:hover{
    background-color: rgba(255, 174, 0, 0.959);
   
   }
  
.discovermaindic{
    
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 1024px) and (min-width: 701px)  {
    .image-end{
        display: none;
    }
}
@media only screen and (max-width: 700px) and (min-width: 325px)  {
    .imgtransparent{
        display: none;
    }
}