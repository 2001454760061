.name_padd{
    padding-left: 12px;
}
.status_padd{
    padding-right: 12px;
}
.background{
    background-color: #e2e8ef !important;
    overflow-x: scroll;
    width: 100%;
}
.chatbot_header{
    background-color: rgb(39, 95, 117);
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding: 4px !important;
    display: flex;
}
.chatbotmessagediv::-webkit-scrollbar{
    width: 7px;
    
    background-color: rgba(117, 117, 117, 0.473);
    
}
.emojiicon{
    font-size: 25px;
    margin-right: 5px;
    cursor: pointer;
}
.indiviconarow{
    display: flex;
}
.inputemailchatbot{
    padding: 5px;
    outline: none;
    margin-right: 5px;
}
.chatbotmessagediv::-webkit-scrollbar-thumb{
    background: gray;
    border-radius: 2px;
}
.chatbottexthead{
    width: 90%;
    padding-left: 10px;
}
.chatbottexthead2{
    width: 90%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 25px;
}
.clsoeicon{
    width: 10%;
    font-size: 25px;
    color: white;
    cursor: pointer;
}
.chatbotmessagediv{
    overflow-y: scroll;
    height: 60vh;
    border: 1px solid lightgray;
    background-color: white;
}
.img_profile{
    width: 36px;
    border-radius: 50%;
}
.custom_rebot_chat{
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.342);
    border-radius: 4px;
    width: fit-content !important;
}
.custom_rtl{
    direction: rtl !important;
    padding: 9px !important;
}
.space_box{
    margin-left: 4px !important;
    padding: 5px !important;
    font-size: 14px !important;
}
.rwotable{
    margin: auto;
}
.space_box_user{
    margin-right: 4px !important;
}
.text_send{
    height: 62px !important;
    border-radius: 0px !important;
}
.snd_icon{
    font-size: 29px !important;
    color: #0b1277 !important;
}
.custom_send{
    border: 0px !important;
}
.mesegtetxher{
    margin: 0px;
    padding: 0px;
}
.messagechatbot:focus{
    box-shadow: none !important;
}
.heart{
    color: red !important;
}
.borderred{
    border: 1px solid red;
}
.messagechatbot{
    border: 0px solid lightgray !important;
    outline: none ;
    border-radius: 0px !important;
    border-right: 0px solid red !important;
}
.custom_position{
    position: fixed;
    position: -webkit-sticky;
    bottom: 47px;
    right: 39px;
    padding-bottom: 3px !important;
    color: white !important;
    font-size: 20px !important;
    border-radius: 100% !important;
    padding: 10px !important;
    background-color: #0b1277 !important;
}
.sticky_bton{
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: 0;
}
.fi_message{
    font-size: 31px !important;
}
.imagetopcrossdiv2{
    position: absolute;
    top: -480%;
}
.backgroundcolorcahtbot{
    background: rgb(39, 95, 117);
    padding: 10px;
    
   clip-path: ellipse(98% 95% at 42% 5%);
}
.treedoticon{
    font-size: 20px;
    
}

.userbiicon{
    font-size: 70px;
    border: 1px solid rgba(211, 211, 211, 0.397);
    padding: 5px;
    border-radius: 10px;
    
}
.backforemailsub{
    background-color: rgba(39, 95, 117, 0.849);
    padding: 5px;
    height: 50vh;
}
.fiarrowicon{
    font-size: 20px;
    color: rgba(39, 95, 117, 0.603);
    
}
.subbtndata{
    background-color: rgb(52, 85, 128);
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    position: relative;
    overflow: hidden;
}

.Cratebotbtn{
    background:#1C2B45;
    padding: 5px;
    border-radius: 5px;
    color: rgb(40, 59, 14);
    color: white;
    letter-spacing: 1px;
    border: 2px solid rgb(43, 70, 43);
    font-family: monospace;
}
.Cratebotbtn2{
    background:#1C2B45;
    padding: 5px;
    border-radius: 5px;
    color: rgb(40, 59, 14);
    color: white;
    letter-spacing: 1px;
    border: 2px solid rgb(43, 70, 43);
    font-family: monospace;
    margin-left: 10px;
}
.Cratebotbtn:hover{ 
    background: rgb(30, 107, 121);
    color: white;
}
.Cratebotbtn2:hover{ 
    background: rgb(30, 107, 121);
    color: white;
}
.secondwidgeetoffline{
    border: 1px solid lightgray;
    width: 100%;
    height: 60vh;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    bottom: 0%;
    right: 0%;
    transition: .6s;
}
.subbtndata:hover{
    background-color: rgb(17, 49, 90);
}
.divforcreatecahtbot{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.treedoticon2{
    font-size: 30px;
    color: rgb(251, 255, 0);
}
.inputlinkchatbot{
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: 1px solid lightgray;
}
.divfornext{
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 70px;
    display: flex;
    justify-content: center;
    color: white;
    background-color: rgb(0, 0, 0);
    cursor: pointer;
}
.imagetopcrossdiv2 img{
    width: 300px;
    height: 290px;
}
.btncrostop2{
    position: absolute;
    top: -120px;
    right: 15%;
}
@media only screen and(min-width: 370px) and(max-width: 700px) {
    
}