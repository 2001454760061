.inbox-maindiv{
    margin-left: 0px;
     
        height: 100vh;
        background-color: #21324E;
        animation: animationonsidebar .7s linear;
        transition: .7s;
}
.inbox-maindiv::-webkit-scrollbar{
    width: 7px;
    background-color: lightgray;
    
}
.inbox-maindiv::-webkit-scrollbar-thumb{
    background: rgb(28, 43, 69);
    border-radius: 0px;
}

.chatdivin{
    width: 100%;
    margin: 0px !important;
}
.inboxCahtsys{
    padding: 0px !important;
    overflow: hidden;
}

.inboxprofdiv{
    height: 80vh;
    overflow-y: scroll;
}
.searchinput{
    border: 1px solid lightgray;
    outline: none;
    padding: 10px;
    border-radius: 7px;
    width: 90%;
}
.inboxprofdiv::-webkit-scrollbar{
    display: block;
    width: 3px ;
    border-radius: 3px;
}
.inboxprofdiv::-webkit-scrollbar-thumb{
    width: 3px;
    color: black;
    background-color: rgb(0, 255, 200) ;
}
.inboxprofdiv::-webkit-scrollbar-track{
    width: 3px ;
    background-color: rgba(220, 220, 220, 0.521) ;
}

.uppersearch{
    /* border: 1px solid lightblue; */
    margin-bottom: 10px;
    padding: 7px;
}
.conver2{
    color: #dc3545;
    cursor: pointer;
    display: none;
}

.inputsearch{
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
    padding: 10px;
    width: 270px;
}
.searchiocon{
    color: black;
    font-size: 25px;
    padding: 2px;
    cursor: pointer;
    z-index: 50;
}
.searchioconforward{
    color: white;
    background-color: #636464;
    font-size: 25px;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
    z-index: 50;
}
.conver{
    color: white;
    padding: 0px 10px;
    font-weight: bold;
}
.inboxUserdetail{
    border-bottom: 1px solid rgba(211, 211, 211, 0.322);
    background-color: #263b5e;
    padding: 3px 10px;
}
.inboxUserdetail2{
    border-bottom: 1px solid rgba(211, 211, 211, 0.322);
    background-color: rgba(25, 25, 58, 0.829);
    padding: 3px 10px;
}
.inboxUserdetail:hover{
    background-color: rgba(25, 25, 58, 0.829);
}
.inboxUserdetail2:hover{
    background-color: rgba(25, 25, 58, 0.829);
}
.emailofinbos{
    font-size: 12px;
    color: rgba(255, 255, 255, 0.678);
}
.userimage{
    width: 50px;
    height: 50px;
    padding: 5px 5px 5px 7px;
    border-radius: 50%;
}
.usercardinfo{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-top: 7px;
}
.usernametext{
    font-size: 14px;
    font-weight: bold;
}
.userpara{
    font-size: 12px;
}
@media only screen and (min-width : 370px) and (max-width : 767px) {
    .inbox-maindiv{
        position: absolute;
        width: 86% !important;
        z-index: 1;
        height: 100vh;
    }
    .chatbottextare{
        z-index: 10;
    }
    .conver2{
        display: block;
        cursor: pointer;
    }
    
}

