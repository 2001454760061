.chat_page{
    font-display: flex;
}
.socialicon{
    font-size: 35px;
    margin: 10px 5px;
}
.contatchead{
    text-align: center;
    font-family: serif;
}
.tablemaindivofcol{
    width: 100% !important;
}
.socialicon:hover{
   color: rgb(6, 6, 122);
   cursor: pointer;
}
.tidiologohere{
    border: 1px solid rgba(211, 211, 211, 0.466);
    
    border-radius: 10px;
    background: linear-gradient(283deg, rgba(0,0,0,1) 0%, rgb(0, 11, 222) 99%);
    width: 80%;
    height: 100px;
    margin: auto;
    color: white;
   display: flex;
   align-items: center;
   justify-content: center;
   box-shadow: 2px 2px 20px lightgray;
    animation: shadowtext 5s linear infinite;
}
@keyframes shadowtext {
    0%{
        text-shadow: 0px 0px 0px red;
    }
    25%{
        text-shadow: 1px 1px 6px rgb(0, 255, 42);
    }
    50%{
        text-shadow: 1px 1px 6px rgb(0, 47, 255);
    }
    75%{
        text-shadow: 1px 1px 6px rgb(255, 0, 0);
    }
    100%{
        text-shadow: 1px 1px 6px rgb(255, 145, 0);
    }
}
.delebtnrow{
    border-radius: 5px;
    outline: none;
    font-size: 25px;
}
.delebtnrow:hover{
    color: red !important;
    cursor: pointer;
}
@media only screen and (min-width : 370px) and (max-width: 500px) {
   .rowfortable{
       width: 370px;
       overflow-x: scroll;
   }
}