.proinpt{
    padding: 5px;
    border: 1px solid lightgray;
    width: 100%;
    outline: none;
    height: 45px;
    border-radius: 3px;
}
.proinpt:focus{
    outline: 2px solid rgb(68, 183, 221);
    border: 1px solid white;
}
.indat{
    margin: auto;
}