.VerifyMailbtn{
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(0, 90, 94);
    color: white;
    height: 45px;
}
.VerifyMailbtn:hover{
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(16, 59, 61);
    color: white;
    height: 45px;
}
.verifyheadandicon{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.maindivemailverify{
    width: 550px;
    padding: 20px;
    margin: 60px auto;
    border-radius: 10px;
    box-shadow: 1px 1px 5px lightblue;
}
.iconverifymail{
    font-size: 100px;
   margin-bottom: 10px;
   
}
.verifyheadandicon h5{
    animation: verifylink 3s ease-in-out infinite;
}
@keyframes verifylink {
    form{
     
    }
    to{
     
    }
}