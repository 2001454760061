.carouselBackgroundcolor{
    background-color: rgb(9, 65, 110);
    height: auto;
    padding: 30px;
   
}
.textcarol{
    width: 100%;
    height: auto;
   animation: textcarolanim 1s linear;
}
@keyframes textcarolanim {
    0%{
        padding-left: 8rem;
    }
    100%{
        padding-left: 0px;
    }
}
.textcaroli{
    display: flex;
    position: relative;
    top: 20%;
    left: 15%;
   margin: 10px 0px;
    justify-content: center;
    align-items: center;
}
.textcarolinfo{
    width: 380px;
    font-size: 20px;
    line-height: 40px;
}
.textcarolinfo p{
        font-size: 25px;
        
}
.imagecarolinnderdiv{
    margin-left: 150px;
    
}
.headingcarol{
    padding-top: 30px;
}
.saleimgcarol{
    width: 460px;
    height: 460px;
    

}
@media only screen and (max-width: 890px) and (min-width: 325px)  {
    .textcaroli{
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 10px;
        left: 5%;
    }
    .textcarolinfo{
        width: 314px; 
    }
    .textcarolinfo p{
        font-size: 22px;
        padding: 0px 10px;
    }
    .imagecarolinnderdiv{
        margin-left: 0px;
    }
    .saleimgcarol{
        width: 330px;
        height: 330px;
        
    
    }
}